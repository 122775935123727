import React from "react";

import { Link, graphql, navigate } from "gatsby";
import { useSwipeable } from "react-swipeable";
import Layout from "../components/Layout";
import ChevronLeft from "../images/chevron-left.svg";
import ChevronRight from "../images/chevron-right.svg";

export default function Page({ data }) {
    const edges = data.allSitePage.edges;
    let slug = data.markdownRemark.fields.slug;
    let index;
    index = edges.findIndex(function(edge) {
        if (!edge.node.context) return "";
        return edge.node.context.slug === slug;
    });
    let thisItem = edges[index].node;
    let thisPage = {};
    let previousPage = {};
    let nextPage = {};
    if (thisItem.context.name) {
        thisPage = { url: thisItem.path, name: thisItem.context.name, index };
    }
    if (index > 0) {
        thisItem = edges[index-1].node;
        previousPage = { url: thisItem.path, name: thisItem.context.name, index: index-1 };
    }
    thisItem = edges[index+1].node;
    if (thisItem.context && thisItem.context.slug) {
        nextPage = { url: thisItem.path, name: thisItem.context.name, index: index+1 };
    }

    let pages = { thisPage, previousPage, nextPage };
    const page = data.markdownRemark;

    // Für die Buttons am Seitenende
    let buttons;
    if (pages.thisPage) {
        buttons = (
            <>
                {pages.previousPage.url ? (
                    <div className="previousPage">
                        <Link className="previousPage" to={pages.previousPage.url}><img src={ChevronLeft} alt="Zurück" className="chevronLeft" ></img></Link>
                    </div>
                ) : ""}
                {pages.nextPage.url ? (
                    <div className="nextPage">
                        <Link className="nextPage" to={pages.nextPage.url}><img src={ChevronRight} alt="Zurück" className="chevronRight" ></img></Link>
                    </div>
                ) : ""}
            </>
        );
    }
    let mobileButtons;
    if (pages.thisPage) {
        mobileButtons = (
            <>
                {pages.previousPage.url ? (
                    <div className="prev">
                        <Link className="prev" to={pages.previousPage.url}><img src={ChevronLeft} alt="Zurück" className="mobileChevronLeft" ></img></Link>
                    </div>
                ) : ""}
                {pages.nextPage.url ? (
                    <div className="next">
                        <Link className="next" to={pages.nextPage.url}><img src={ChevronRight} alt="Zurück" className="mobileChevronRight" ></img></Link>
                    </div>
                ) : ""}
            </>
        );
    }
    
    const handlers = useSwipeable({
        onSwipedRight: () => {
            navigate(pages.previousPage.url);
        },
        onSwipedLeft: () => {
            navigate(pages.nextPage.url);
        }
    });

    return (
        <div {...handlers}>
            <Layout edges={edges} pages={pages}>
                <div className="template">
                    <div className="templateContent">
                        <h1 className="templateTitle">{page.frontmatter.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: page.html }} />
                        {mobileButtons ? mobileButtons : ""}
                    </div>
                    {buttons ? buttons : ""}
                </div>
                <div>
                </div>
            </Layout>
        </div>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                menu
                title
            }
            fields {
                slug
            }
        }
        allSitePage(sort: {fields: context___slug}) {
            edges {
                node {
                    context {
                        slug
                        index
                        name
                        parent {
                            index
                        }
                        children {
                            index
                        }
                    }
                    path
                }
            }
        }
    }
`
